<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-color"
      :class="{
        'login-signin-on': this.state == 'signin',

        'login-forgot-on': this.state == 'forgot',
        'login-verify-on': this.state == 'verify',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-center center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
          style="background-size: cover"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <img
        src="/media/logos/scooble_logo_white.png"
        alt="Logo"
        style="top: 20px; right: 20px; position: absolute; width: 250px"
      />
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-white font-size-h4 font-size-h1-lg">Welcome!</h3>
                <h6 class="font-weight-bold text-white font-size-h6">
                  Sign up now for your event in the scooble app to publish, manage and market.
                </h6>
              </div>
              <div class="form-group">
                <template v-if="getErrors.length > 0">
                  <div
                    role="alert"
                    v-bind:class="{
                      show: getErrors.length,
                    }"
                    class="alert fade alert-danger"
                  >
                    <div class="alert-text">
                      {{ getErrors }}
                    </div>
                  </div>
                </template>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input
                    class="form-control form-control-solid py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div style="display: flex" id="example-input-group-2" label="" label-for="example-input-2">
                  <input
                    v-bind:type="[showPassword ? 'text' : 'password']"
                    class="form-control form-control-solid py-7 px-6 rounded-lg"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                    placeholder="Password"
                    style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important"
                  />
                  <div class="input-group-append">
                    <span
                      @click="showPassword = !showPassword"
                      class="input-group-text"
                      style="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important"
                    >
                      <i :class="[showPassword ? 'far fa-eye' : 'fas fa-eye-slash']" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-n5 mb-3">
                <a
                  class="text-white font-size-h6 font-weight-bolder text-center pt-5"
                  id="kt_login_forgot"
                  @click="showForm('forgot')"
                  >Forgot Password?</a
                >
              </div>
              <b-row>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <button
                    ref="kt_login_signin_submit"
                    class="btn btn-warning font-weight-bold px-8 py-4 my-3 font-size-lg w-100"
                  >
                    Sign In
                  </button>
                </b-col>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <!-- <button
                    type="button"
                    class="btn btn-light-primary font-weight-bold px-8 py-4 my-3 font-size-lg w-100"
                  >
                    <span class="svg-icon svg-icon-md"> <inline-svg src="media/svg/social-icons/google.svg" /> </span
                    >Sign in with Google
                  </button> -->
                </b-col>
              </b-row>
            </form>
          </div>
          <!--end::Signin-->

          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <template v-if="getErrors.length > 0">
              <div
                role="alert"
                v-bind:class="{
                  show: getErrors.length,
                  'alert fade alert-danger': getStatus === 401,
                  'alert fade alert-success': getStatus === 200,
                }"
              >
                <div class="alert-text">
                  {{ getErrors }}
                </div>
              </div>
            </template>
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-white font-size-h4 font-size-h1-lg">Forgot your password?</h3>
                <h6 class="font-weight-bold text-white font-size-h6">
                  Enter your e-mail address below to reset your password.
                </h6>
              </div>
              <div class="form-group">
                <input
                  v-model="email"
                  class="form-control form-control-solid py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>

              <b-row>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <!-- <button
                    v-on:click="forgotPassword()"
                    type="button"
                    ref="kt_forgot_password_submit"
                    class="btn btn-warning font-weight-bold px-8 py-4 my-3 w-100"
                  >
                    Send Password reset Link.
                  </button> -->
                  <button ref="kt_forgot_password_submit" class="btn btn-warning font-weight-bold px-8 py-4 my-3 w-100">
                    Send Password reset Link.
                  </button>
                </b-col>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bold px-8 py-4 my-3 w-100"
                    @click="showForm('signin')"
                  >
                    Back
                  </button>
                </b-col>
              </b-row>
            </form>
          </div>
          <!--end::Forgot-->
          <!--begin::Verify-Forgot-->
          <div class="login-form login-verify">
            <div class="pb-13 pt-lg-0 pt-5">
              <h3 class="font-weight-bolder text-white font-size-h4 font-size-h1-lg">Forgot your password?</h3>
              <h6 class="font-weight-bold text-white font-size-h6">Reset it here.</h6>
            </div>
            <template v-if="getErrors.length > 0">
              <div
                role="alert"
                v-bind:class="{
                  show: getErrors.length,
                }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  {{ getErrors }}
                </div>
              </div>
            </template>
            <!--begin::Form-->
            <!-- NOTE : Reset Password Code Confirm View -->
            <form class="form" novalidate="novalidate" id="kt_login_verify_form" ref="kt_login_verify_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-white font-size-h4 font-size-h1-lg">New Password</h3>
                <h6 class="font-weight-bold text-white font-size-h6">
                  Please enter the code sent by e-mail and create a password.
                </h6>
              </div>
              <div class="form-group">
                <input
                  v-model="verifyCode"
                  class="form-control form-control-solid py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Verify Code"
                  name="verifyCode"
                  ref="verifyCode"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  v-model="email"
                  class="form-control form-control-solid py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="E-Mail Address"
                  name="email"
                  autocomplete="off"
                  readonly
                />
              </div>
              <div class="form-group">
                <input
                  v-model="password"
                  class="form-control form-control-solid py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  ref="password"
                  placeholder="Password"
                  name="password"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  v-model="confirmPassword"
                  class="form-control form-control-solid py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  autocomplete="off"
                  ref="confirm_password"
                />
              </div>

              <b-row>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <!-- <button
                    v-on:click="resetPassword()"
                    type="button"
                    class="btn btn-warning font-weight-bold px-8 py-4 my-3 w-100"
                  >
                    Reset Password
                  </button> -->
                  <button ref="kt_change_password_submit" class="btn btn-warning font-weight-bold px-8 py-4 my-3 w-100">
                    Reset Password
                  </button>
                </b-col>
              </b-row>
            </form>
          </div>
          <!--end-->
        </div>
        <!--begin::Content footer-->

        <div class="d-flex justify-content-lg-start justify-content-center p-7 py-lg-0">
          <b-row>
            <b-col xl="2" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/impressum/" class="text-white font-weight-bold">Imprint</a></b-col
            >
            <b-col xl="5" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/agb/" class="text-white font-weight-bold">Terms&Conditions</a></b-col
            >
            <b-col xl="5" lg="12" md="12" sm="12" cols="12">
              <a href="https://www.scooble.de/datenschutz/" class="text-white font-weight-bold"
                >Data Protection</a
              ></b-col
            ></b-row
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core'
import UserServices from '../../../../core/services/users'
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger'
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap'
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton'

import KTUtil from '@/assets/js/components/util'
import { mapGetters } from 'vuex'
import { LOGIN, LOGOUT, FORGOT_PASSWORD } from '@/core/services/store/auth.module'
import Swal from 'sweetalert2'
import { CONFIRM_CHANGE_PASSWORD } from '../../../../core/services/store/auth.module'

export default {
  name: 'login-1',
  data() {
    return {
      state: 'signin',
      // Remove this dummy login info
      form: {
        email: '',
        password: '',
      },
      email: '',
      password: '',
      verifyCode: '',
      confirmPassword: '',
      userId: '',
      showPassword: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'getStatus', 'getErrors']),

    backgroundImage() {
      return process.env.BASE_URL + 'media/logos/scooble-start-bild.jpg'
    },
  },
  mounted() {
    const signin_form = KTUtil.getById('kt_login_signin_form')

    const forgot_form = KTUtil.getById('kt_login_forgot_form')
    const verify_form = KTUtil.getById('kt_login_verify_form')

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: 'Username is required',
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: 'Password is required',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: 'Email is required',
            },
            emailAddress: {
              message: 'The value is not a valid email address',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })

    this.fv3 = formValidation(verify_form, {
      fields: {
        verifyCode: {
          validators: {
            notEmpty: {
              message: 'VerifyCode is required',
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: 'New password is required',
            },
          },
        },
        confirm_password: {
          validators: {
            notEmpty: {
              message: 'Confirm password is required',
            },
            identical: {
              compare: function () {
                return verify_form.querySelector('[name="password"]').value
              },
              message: 'The two passwords you entered do not match.',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })

    // ****** login ******
    this.fv.on('core.form.valid', () => {
      var email = this.form.email
      var password = this.form.password

      // clear existing errors
      this.$store.dispatch(LOGOUT)

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(res =>
            res
              ? this.$router.push({ name: 'dashboard' })
              : Swal.fire({
                  title: '',
                  text: 'Since you are not a Scooble official, you cannot perform this operation.',
                  icon: 'error',
                  confirmButtonClass: 'btn btn-secondary',
                  heightAuto: false,
                })
          )
          .catch(() => {})

        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
      }, 2000)
    })

    this.fv.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false,
      })
    })

    // ****** forgot password send mail ******
    this.fv2.on('core.form.valid', () => {
      const submitButton = this.$refs['kt_forgot_password_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      setTimeout(() => {
        const email = this.email
        this.$store
          .dispatch(FORGOT_PASSWORD, {
            email: email,
          })
          .then(res => {
            if (res) {
              this.userId = res.userId
              this.showForm('verify')
            }
          })
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
      }, 2000)
    })

    this.fv2.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false,
      })
    })

    // ****** confirm change password with forgot password code ******
    this.fv3.on('core.form.valid', () => {
      const submitButton = this.$refs['kt_change_password_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      setTimeout(async () => {
        const code = this.verifyCode

        this.$store
          .dispatch(CONFIRM_CHANGE_PASSWORD, { userId: this.userId, code, email: this.email, password: this.password })
          .then(res => {
            if (res) {
              this.showForm('signin')
            }
          })
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
      }, 2000)
    })

    this.fv3.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false,
      })
    })
  },
  methods: {
    showForm(form) {
      // TODO : alert mesajı sıfırlanıcak
      this.state = form
      var form_name = 'kt_login_' + form + '_form'
      KTUtil.animateClass(KTUtil.getById(form_name), 'animate__animated animate__backInUp')
    },
    async resetPassword() {
      const email = this.email
      const password = this.password

      const changePassword = (await UserServices.forgotPasswordChangePassword(email, password, 'de')).data
      console.log(`changePassword`, changePassword)
      if (changePassword) {
        this.showForm('signin')
      }
    },
    checkRoute() {
      this.state = this.$route.name === 'login' ? 'signin' : this.$route.name
    },
  },
  created() {
    this.checkRoute()
  },
}
</script>

<style>
.bg-color {
  background: #6954a4;
  background: radial-gradient(circle, #6954a4 9%, #3a2080 100%);
}
.btn-warning {
  background: #e45e00 !important;
  border: #e45e00 !important;
}
</style>
